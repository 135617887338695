import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo";
import ContenidoPrincipal from '../components/home/contenido';
import ListaCursos from '../components/cursos/listacursos';
import ListadoObtendras from '../components/home/obtendras';
import Instructor from '../components/home/instructor';
import FAQ from '../components/home/faq';

const IndexPage = () => (
  <Layout>
      <SEO title="Cursos React, Node y JavaScript" />
      
      <ContenidoPrincipal />
      

      <main id="cursos" className="nuestros-cursos">
        <div className="contenedor">
          <h1>Nuestros Cursos</h1>
          <p>Desarrolla en JavaScript, React y Node.js de forma profesional.</p>
          <ListaCursos />
        </div>
      </main>

      <section  className="obtendras contenedor">
        <h2>Que Obtendrás...</h2>
          <ListadoObtendras />
      </section>

      <section id="sobre" className="instructor">
          <Instructor />
      </section>

      <section id="faq" className="faq contenedor">
        <h2>Preguntas y Respuestas</h2>
        <FAQ />
      </section>

     
  </Layout>
)

export default IndexPage;
