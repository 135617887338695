/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header";
import Footer from './footer';

import '../css/normalize.css'
import "../css/layout.css"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => {
      const year = new Date().getFullYear();
      return(
      <>
        <Header  
          encabezado="Conviértete en un Experto en React y JavaScript"
        />
        <main>{children}</main>
        <Footer 
          texto={`Todos los derechos Reservados ${year}`}
        />
      </>
    )}}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
