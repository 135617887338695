import React from 'react';
import video from '../../images/video.png';

const ContenidoPrincipal = () => {
    return ( 
        <div className="contenido-principal contenedor">
            <div className="imagen">
                <img src={video} alt="video" />
            </div>
            <div className="texto">
                <p>Aprende <span>React, Node y JavaScript</span> creando proyectos reales.</p>

                <ul className="aprenderas">
                    <li>JavaScript Moderno ES6+</li>
                    <li>React 16+</li>
                    <li>Node.js para Desarrollo Web con MVC</li>
                    <li>Todo lo necesario para ser un experto</li>
                    <li>Proyectos y problemas del mundo real, así como solucionarlos</li>
                </ul>
            </div>
        </div>
     );
}
 
export default ContenidoPrincipal;