import React from 'react';

const Navegacion = () => {
    return ( 
        <nav className="contenedor navegacion">
            <a href="#cursos">Cursos</a>
            <a href="#sobre">Sobre Mi</a>
            <a href="#faq">Preguntas Frecuentes</a>
        </nav>
     );
}
export default Navegacion;