import React from 'react'

const Footer = ({texto}) => {
    return ( 
        <footer className="site-footer">
            <p>{texto}</p>
        </footer>
     );
}
 
export default Footer;