import React  from 'react';
import { StaticQuery, graphql } from 'gatsby';


const ListadoObtendras = () => (
    <StaticQuery 
        query={graphql`
            query obtendrasQuery {
                allObtendrasJson{
                        edges{
                            node{
                                id,
                                nombre,
                                desc
                            }
                        }
                    }
                }
        `}
        render={data => (
            <>
                <ul className="listado-obtendras">{getObtendras(data)}</ul>
            </>
        )}
    />
)


function getObtendras(data) {
    const listado = [];

    data.allObtendrasJson.edges.forEach(lista => {
        listado.push(    
            <li key={lista.node.id} className="elemento">
                <h3>{lista.node.nombre}</h3>
                <p>{lista.node.desc}</p>
            </li>
        )
    });
    return listado;
}
 
export default ListadoObtendras;