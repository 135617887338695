import PropTypes from "prop-types"
import React from "react"
import Navegacion from './Navegacion';
import logo from '../images/logo.svg';

const Header = ({encabezado}) => (
  <header className="site-header">
      <Navegacion />
      <div className="contenido-header">
          <p className="titulo">{encabezado}</p>
         <img src={logo} className="logo" alt="logotipo"/>
         <p>Aprende React 16, Hooks,  JSX, Redux, React Router, Server Side Rendering, Axios, Fetch y  ¡mucho más! </p>
      </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
