import React  from 'react';
import { StaticQuery, graphql } from 'gatsby';


const FAQ = () => (
    <StaticQuery 
        query={graphql`
            query allFaqQuery {
                allFaqJson{
                        edges{
                            node{
                                id,
                                pregunta,
                                respuesta
                            }
                        }
                    }
                }
        `}
        render={data => (
            <>
                <ul className="listado-faq">
                    {getObtendras(data)}
                </ul>
            </>
        )}
    />
)


function getObtendras(data) {
    const preguntas = [];

    data.allFaqJson.edges.forEach(pregunta => {
        preguntas.push(    
            <li key={pregunta.node.id} className="elemento">
                <h3>{pregunta.node.pregunta}</h3>
                <p>{pregunta.node.respuesta}</p>
            </li>
        )
    });
    return preguntas;
}
 
export default FAQ;