import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

const ListaCursos = ({children}) => (
    <StaticQuery 
        query={graphql`
            query CursosQuery {
                allCursosJson{
                        edges{
                            node{
                                id,
                                icono,
                                nombre,
                                subtitulo,
                                duracion,
                                descripcion,
                                enlace
                            }
                        }
                    }
                }
        `}
        render={data => (
            <>
                <ul className="cursos-lista">{getCursos(data)}</ul>
            </>
        )}
    />
);

function getCursos(data) {
    const cursos = [];

    data.allCursosJson.edges.forEach(curso => {
        cursos.push(    
            <li key={curso.node.id} className="curso">
                <img src={`images/iconos/icono_${curso.node.icono}.svg` } alt="imagen icono"/>

                <h2>{curso.node.nombre}</h2>
                <p className="subtitulo">{curso.node.subtitulo}</p>
                <p className="duracion">{curso.node.duracion}</p>
                <p className="descripcion">{curso.node.descripcion}</p>

                <a target="_blank" rel="noopener noreferrer" href={curso.node.enlace} className="btn btn-verde">Tomar Curso</a>
            </li>
        )
    });
    return cursos;
}

export default ListaCursos;